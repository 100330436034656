define("discourse/plugins/chat/discourse/components/chat-channel-chooser-header", ["exports", "discourse/helpers/d-icon", "discourse-i18n", "select-kit/components/combo-box/combo-box-header", "discourse/plugins/chat/discourse/components/channel-title", "@ember/component", "@ember/template-factory"], function (_exports, _dIcon, _discourseI18n, _comboBoxHeader, _channelTitle, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatChannelChooserHeader extends _comboBoxHeader.default {
    static #_ = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="select-kit-header-wrapper">
          {{#if this.selectedContent}}
            <ChannelTitle @channel={{this.selectedContent}} />
          {{else}}
            {{i18n "chat.incoming_webhooks.channel_placeholder"}}
          {{/if}}
    
          {{icon this.caretIcon class="caret-icon"}}
        </div>
      
    */
    {
      "id": "V2js1mZV",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"select-kit-header-wrapper\"],[12],[1,\"\\n\"],[41,[30,0,[\"selectedContent\"]],[[[1,\"        \"],[8,[32,0],null,[[\"@channel\"],[[30,0,[\"selectedContent\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[32,1],[\"chat.incoming_webhooks.channel_placeholder\"],null]],[1,\"\\n\"]],[]]],[1,\"\\n      \"],[1,[28,[32,2],[[30,0,[\"caretIcon\"]]],[[\"class\"],[\"caret-icon\"]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-channel-chooser-header.js",
      "scope": () => [_channelTitle.default, _discourseI18n.i18n, _dIcon.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatChannelChooserHeader;
});