define("discourse/plugins/chat/discourse/components/chat/modal/edit-channel-description", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/modifier", "@ember/object", "@ember/service", "discourse/components/char-counter", "discourse/components/d-button", "discourse/components/d-modal", "discourse/components/d-modal-cancel", "discourse/helpers/with-event-value", "discourse/lib/ajax-error", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _modifier, _object, _service, _charCounter, _dButton, _dModal, _dModalCancel, _withEventValue, _ajaxError, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DESCRIPTION_MAX_LENGTH = 280;
  class ChatModalEditChannelDescription extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chatApi", [_service.service]))();
    #chatApi = (() => (dt7948.i(this, "chatApi"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "editedDescription", [_tracking.tracked], function () {
      return this.channel.description || "";
    }))();
    #editedDescription = (() => (dt7948.i(this, "editedDescription"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "flash", [_tracking.tracked]))();
    #flash = (() => (dt7948.i(this, "flash"), void 0))();
    get channel() {
      return this.args.model;
    }
    get isSaveDisabled() {
      return this.channel.description === this.editedDescription || this.editedDescription?.length > DESCRIPTION_MAX_LENGTH;
    }
    get descriptionMaxLength() {
      return DESCRIPTION_MAX_LENGTH;
    }
    async onSaveChatChannelDescription() {
      try {
        const result = await this.chatApi.updateChannel(this.channel.id, {
          description: this.editedDescription
        });
        this.channel.description = result.channel.description;
        this.args.closeModal();
      } catch (error) {
        this.flash = (0, _ajaxError.extractError)(error);
      }
    }
    static #_4 = (() => dt7948.n(this.prototype, "onSaveChatChannelDescription", [_object.action]))();
    onChangeChatChannelDescription(description) {
      this.flash = null;
      this.editedDescription = description;
    }
    static #_5 = (() => dt7948.n(this.prototype, "onChangeChatChannelDescription", [_object.action]))();
    static #_6 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DModal
          @closeModal={{@closeModal}}
          class="chat-modal-edit-channel-description"
          @inline={{@inline}}
          @title={{i18n "chat.channel_edit_description_modal.title"}}
          @flash={{this.flash}}
        >
          <:body>
            <span class="chat-modal-edit-channel-description__description">{{i18n
                "chat.channel_edit_description_modal.description"
              }}</span>
            <CharCounter
              @value={{this.editedDescription}}
              @max={{this.descriptionMaxLength}}
            >
              <textarea
                {{on "input" (withEventValue this.onChangeChatChannelDescription)}}
                class="chat-modal-edit-channel-description__description-input"
                placeholder={{i18n
                  "chat.channel_edit_description_modal.input_placeholder"
                }}
              >{{this.editedDescription}}</textarea>
            </CharCounter>
          </:body>
          <:footer>
            <DButton
              @action={{this.onSaveChatChannelDescription}}
              @label="save"
              @disabled={{this.isSaveDisabled}}
              class="btn-primary create"
            />
            <DModalCancel @close={{@closeModal}} />
          </:footer>
        </DModal>
      
    */
    {
      "id": "juDC4SoW",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"chat-modal-edit-channel-description\"]],[[\"@closeModal\",\"@inline\",\"@title\",\"@flash\"],[[30,1],[30,2],[28,[32,1],[\"chat.channel_edit_description_modal.title\"],null],[30,0,[\"flash\"]]]],[[\"body\",\"footer\"],[[[[1,\"\\n        \"],[10,1],[14,0,\"chat-modal-edit-channel-description__description\"],[12],[1,[28,[32,1],[\"chat.channel_edit_description_modal.description\"],null]],[13],[1,\"\\n        \"],[8,[32,2],null,[[\"@value\",\"@max\"],[[30,0,[\"editedDescription\"]],[30,0,[\"descriptionMaxLength\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[11,\"textarea\"],[24,0,\"chat-modal-edit-channel-description__description-input\"],[16,\"placeholder\",[28,[32,1],[\"chat.channel_edit_description_modal.input_placeholder\"],null]],[4,[32,3],[\"input\",[28,[32,4],[[30,0,[\"onChangeChatChannelDescription\"]]],null]],null],[12],[1,[30,0,[\"editedDescription\"]]],[13],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[8,[32,5],[[24,0,\"btn-primary create\"]],[[\"@action\",\"@label\",\"@disabled\"],[[30,0,[\"onSaveChatChannelDescription\"]],\"save\",[30,0,[\"isSaveDisabled\"]]]],null],[1,\"\\n        \"],[8,[32,6],null,[[\"@close\"],[[30,1]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\",\"@inline\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/modal/edit-channel-description.js",
      "scope": () => [_dModal.default, _discourseI18n.i18n, _charCounter.default, _modifier.on, _withEventValue.default, _dButton.default, _dModalCancel.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatModalEditChannelDescription;
});