define("discourse/plugins/chat/discourse/components/chat-channel-chooser-row", ["exports", "@ember-decorators/component", "select-kit/components/select-kit/select-kit-row", "discourse/plugins/chat/discourse/components/channel-title", "@ember/component", "@ember/template-factory"], function (_exports, _component, _selectKitRow, _channelTitle, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ChatChannelChooserRow = dt7948.c(class ChatChannelChooserRow extends _selectKitRow.default {
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      <ChannelTitle @channel={{this.item}} />
    */
    {
      "id": "3Qsb8CtV",
      "block": "[[[8,[32,0],null,[[\"@channel\"],[[30,0,[\"item\"]]]],null]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-channel-chooser-row.js",
      "scope": () => [_channelTitle.default],
      "isStrictMode": true
    }), this))();
  }, [(0, _component.classNames)("chat-channel-chooser-row")]);
  var _default = _exports.default = ChatChannelChooserRow;
});