define("discourse/plugins/chat/discourse/templates/chat-direct-messages", ["exports", "ember-route-template", "discourse/plugins/chat/discourse/components/chat/routes/direct-messages", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _emberRouteTemplate, _directMessages, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _emberRouteTemplate.default)((0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    <DirectMessages />
  */
  {
    "id": "yOFw3wWg",
    "block": "[[[8,[32,0],null,null,null]],[],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/templates/chat-direct-messages.js",
    "scope": () => [_directMessages.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, undefined)));
});