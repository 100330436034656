define("discourse/plugins/chat/discourse/templates/chat-browse", ["exports", "ember-route-template", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat/navbar", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _emberRouteTemplate, _discourseI18n, _navbar, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _emberRouteTemplate.default)((0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="c-routes --browse">
        <Navbar as |navbar|>
          <navbar.BackButton />
          <navbar.Title @title={{i18n "chat.browse.title"}} />
  
          <navbar.Actions as |a|>
            <a.NewChannelButton />
            <a.OpenDrawerButton />
          </navbar.Actions>
        </Navbar>
  
        {{outlet}}
      </div>
    
  */
  {
    "id": "RzvkAPeF",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"c-routes --browse\"],[12],[1,\"\\n      \"],[8,[32,0],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[30,1,[\"BackButton\"]],null,null,null],[1,\"\\n        \"],[8,[30,1,[\"Title\"]],null,[[\"@title\"],[[28,[32,1],[\"chat.browse.title\"],null]]],null],[1,\"\\n\\n        \"],[8,[30,1,[\"Actions\"]],null,null,[[\"default\"],[[[[1,\"\\n          \"],[8,[30,2,[\"NewChannelButton\"]],null,null,null],[1,\"\\n          \"],[8,[30,2,[\"OpenDrawerButton\"]],null,null,null],[1,\"\\n        \"]],[2]]]]],[1,\"\\n      \"]],[1]]]]],[1,\"\\n\\n      \"],[46,[28,[31,1],null,null],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"navbar\",\"a\"],false,[\"component\",\"-outlet\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/templates/chat-browse.js",
    "scope": () => [_navbar.default, _discourseI18n.i18n],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, undefined)));
});