define("discourse/plugins/chat/discourse/templates/chat-channel-info-members", ["exports", "ember-route-template", "discourse/plugins/chat/discourse/components/chat/routes/channel-info-members", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _emberRouteTemplate, _channelInfoMembers, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _emberRouteTemplate.default)((0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    <ChannelInfoMembers @channel={{@controller.model}} />
  */
  {
    "id": "275Sk0bE",
    "block": "[[[8,[32,0],null,[[\"@channel\"],[[30,1,[\"model\"]]]],null]],[\"@controller\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/templates/chat-channel-info-members.js",
    "scope": () => [_channelInfoMembers.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, undefined)));
});