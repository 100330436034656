define("discourse/plugins/chat/discourse/templates/chat-channel-thread", ["exports", "ember-route-template", "discourse/plugins/chat/discourse/components/chat/routes/channel-thread", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _emberRouteTemplate, _channelThread, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _emberRouteTemplate.default)((0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <ChannelThread
        @thread={{@controller.model}}
        @targetMessageId={{@controller.targetMessageId}}
      />
    
  */
  {
    "id": "zYjW6drO",
    "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@thread\",\"@targetMessageId\"],[[30,1,[\"model\"]],[30,1,[\"targetMessageId\"]]]],null],[1,\"\\n  \"]],[\"@controller\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/templates/chat-channel-thread.js",
    "scope": () => [_channelThread.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, undefined)));
});