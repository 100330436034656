define("discourse/plugins/chat/discourse/templates/chat", ["exports", "ember-route-template", "discourse/helpers/concat-class", "discourse/plugins/chat/discourse/components/channels-list", "discourse/plugins/chat/discourse/components/chat-footer", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _emberRouteTemplate, _concatClass, _channelsList, _chatFooter, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _emberRouteTemplate.default)((0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div id="chat-progress-bar-container"></div>
  
      <div
        class={{concatClass
          "full-page-chat"
          (if @controller.shouldUseCoreSidebar "full-page-chat-sidebar-enabled")
        }}
      >
        {{#if @controller.shouldUseChatSidebar}}
          <ChannelsList />
        {{/if}}
  
        <div
          id="main-chat-outlet"
          class={{concatClass
            "main-chat-outlet"
            @controller.mainOutletModifierClasses
          }}
        >
          {{outlet}}
          {{#if @controller.shouldUseChatFooter}}
            <ChatFooter />
          {{/if}}
        </div>
      </div>
    
  */
  {
    "id": "1J1z/GKI",
    "block": "[[[1,\"\\n    \"],[10,0],[14,1,\"chat-progress-bar-container\"],[12],[13],[1,\"\\n\\n    \"],[10,0],[15,0,[28,[32,0],[\"full-page-chat\",[52,[30,1,[\"shouldUseCoreSidebar\"]],\"full-page-chat-sidebar-enabled\"]],null]],[12],[1,\"\\n\"],[41,[30,1,[\"shouldUseChatSidebar\"]],[[[1,\"        \"],[8,[32,1],null,null,null],[1,\"\\n\"]],[]],null],[1,\"\\n      \"],[10,0],[14,1,\"main-chat-outlet\"],[15,0,[28,[32,0],[\"main-chat-outlet\",[30,1,[\"mainOutletModifierClasses\"]]],null]],[12],[1,\"\\n        \"],[46,[28,[31,2],null,null],null,null,null],[1,\"\\n\"],[41,[30,1,[\"shouldUseChatFooter\"]],[[[1,\"          \"],[8,[32,2],null,null,null],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@controller\"],false,[\"if\",\"component\",\"-outlet\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/templates/chat.js",
    "scope": () => [_concatClass.default, _channelsList.default, _chatFooter.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, undefined)));
});