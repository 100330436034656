define("discourse/plugins/chat/discourse/components/collapser", ["exports", "@ember/component", "@ember/object", "@ember-decorators/component", "discourse/components/d-button", "discourse/helpers/concat-class", "@ember/template-factory"], function (_exports, _component, _object, _component2, _dButton, _concatClass, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Collapser = dt7948.c(class Collapser extends _component.default {
    collapsed = false;
    header = null;
    onToggle = null;
    open() {
      this.set("collapsed", false);
      this.onToggle?.(false);
    }
    static #_ = (() => dt7948.n(this.prototype, "open", [_object.action]))();
    close() {
      this.set("collapsed", true);
      this.onToggle?.(true);
    }
    static #_2 = (() => dt7948.n(this.prototype, "close", [_object.action]))();
    static #_3 = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="chat-message-collapser-header">
          {{this.header}}
    
          {{#if this.collapsed}}
            <DButton
              @action={{this.open}}
              @icon="caret-right"
              class="chat-message-collapser-button chat-message-collapser-closed"
            />
          {{else}}
            <DButton
              @action={{this.close}}
              @icon="caret-down"
              class="chat-message-collapser-button chat-message-collapser-opened"
            />
          {{/if}}
        </div>
    
        <div
          class={{concatClass
            "chat-message-collapser-body"
            (if this.collapsed "hidden")
          }}
        >
          {{yield this.collapsed}}
        </div>
      
    */
    {
      "id": "5YSNqkmZ",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-message-collapser-header\"],[12],[1,\"\\n      \"],[1,[30,0,[\"header\"]]],[1,\"\\n\\n\"],[41,[30,0,[\"collapsed\"]],[[[1,\"        \"],[8,[32,0],[[24,0,\"chat-message-collapser-button chat-message-collapser-closed\"]],[[\"@action\",\"@icon\"],[[30,0,[\"open\"]],\"caret-right\"]],null],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[32,0],[[24,0,\"chat-message-collapser-button chat-message-collapser-opened\"]],[[\"@action\",\"@icon\"],[[30,0,[\"close\"]],\"caret-down\"]],null],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\\n    \"],[10,0],[15,0,[28,[32,1],[\"chat-message-collapser-body\",[52,[30,0,[\"collapsed\"]],\"hidden\"]],null]],[12],[1,\"\\n      \"],[18,1,[[30,0,[\"collapsed\"]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"&default\"],false,[\"if\",\"yield\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/collapser.js",
      "scope": () => [_dButton.default, _concatClass.default],
      "isStrictMode": true
    }), this))();
  }, [(0, _component2.tagName)("")]);
  var _default = _exports.default = Collapser;
});